import React, { useState } from "react";
import * as termsStyles from "../../styles/components/terms.module.scss";
import Layout from "../../components/layout";
import { SEO } from "../../components/seo";
import { graphql, navigate } from "gatsby";
import parse from "html-react-parser";
import { getPageByLanguage } from "../../hooks/get-page-by-language";
import useCookie from "../../hooks/use-cookie";
import { getBrowserLanguage } from "../../hooks/get-language";
import { getHeaderAndFooter } from "../../hooks/get-header-and-footer";

const Terms = ({ data, location }) => {
  const [dropdown, setDropdown] = useState({
    key: "",
    name: "",
  });
  const [cookie, setCookie] = useCookie(
    "language",
    getBrowserLanguage(location)
  );

  const { foundHeader, foundFooter } = getHeaderAndFooter(data, cookie);
  let findTerms = data.allContentstackTermsAndConditions.nodes.filter(
    (term) => {
      if (!term.url.includes("terms")) {
        return location.pathname.includes(term.url);
      }
    }
  );
  let foundTermsAndConditionsByLocale = getPageByLanguage(
    findTerms,
    false,
    cookie
  );

  const isBrowser = typeof window !== "undefined";
  if (!foundTermsAndConditionsByLocale && isBrowser) {
    navigate("/404/");
  }

  let splitContent = foundTermsAndConditionsByLocale?.content
    .split("<br/>")
    .filter((el) => el !== "");
  return (
    <Layout
      navigation={foundHeader}
      footer={foundFooter}
      dropdown={dropdown}
      setDropdown={setDropdown}
      cookie={cookie}
      setCookie={setCookie}
    >
      <section className={termsStyles.termsContainer}>
        <h1 className={termsStyles.mainTitle}>
          {foundTermsAndConditionsByLocale?.title}
        </h1>
        <p className="p-medium">
          {splitContent?.map((text, idx) => (
            <span className={termsStyles.splitContent} key={idx}>
              {parse(text)}
            </span>
          ))}
        </p>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    allContentstackHeader {
      nodes {
        locale
        menu {
          link {
            href
            title
          }
        }
        logo {
          logo_title
          logo_image {
            url
          }
        }
      }
    }
    allContentstackFooter {
      nodes {
        locale
        address {
          street
          city_state_zip
          phone_number
          email
        }
        company_motto {
          motto_line_1
          motto_line_2
        }
        copyright
        locale
        title
        uid
        secondary_menu {
          link {
            href
            title
          }
        }
        main_links {
          link {
            href
            title
          }
        }
        logo {
          logo_image {
            url
          }
          logo_title
        }
      }
    }
    allContentstackTermsAndConditions {
      nodes {
        locale
        url
        title
        content
      }
    }
  }
`;

export default Terms;
export const Head = () => <SEO title="Elevate Outdoor Collective | Terms" />;
